import {createRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeEurope, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  nextCategory,
  selectFeature,
  setCurrentStep,
  setCurrentCategory,
  toggleBasketVisible
} from '../configuratorSlice';
import {totalPrice, triggerGaEvent} from '../../../app/utils';
import Price from '../../../components/Price';
import Highlight from '../../../components/Highlight';
import BtnLanguage from '../actionbar/btnlanguage/BtnLanguage';
import Feature from './feature/Feature';
import NavBar from '../navbar/NavBar';
import Nav from '../gallery/nav/Nav';
import './SideBar.scss';

export default function SideBar() {
  const { t } = useTranslation();
  const [myOptionsClicked, setMyOptionsClicked] = useState(false); // Used to send GA event only once
  const product = useSelector(state => state.configurator.product);
  const version = useSelector(state => state.configurator.version);
  const features = useSelector(state => state.configurator.features);
  const userFeatures = useSelector(state => state.configurator.userFeatures);
  const categories = useSelector(state => state.configurator.categories);
  const currentCategory = useSelector(state => state.configurator.currentCategory);
  const currentStep = useSelector(state => state.configurator.currentStep);
  const dispatch = useDispatch();

  // Merge product features and version specific features for current category
  const mergedFeatures = [
    ...features
      .filter(f => !version.features.some(vf => vf.feature.id === f.id)), // Only features which are not specific to the current version
    ...version.features
      .filter(vf => {
        // Check if the feature is in a sub category or in a main category
        const isMainCategory = categories.some(c => c['@id'] === vf.feature.category);
        if (isMainCategory) {
          return currentStep && vf.feature.category === categories[currentStep]['@id'];
        } else {
          return currentCategory && vf.feature.category === currentCategory['@id'];
        }
      }) // Only features for current category
      .filter(vf => !vf.unavailable) // Remove features marked as unavailable for this version
  ].sort((f1, f2) => {
    const f1Position = f1['@type'] === 'Feature' ? f1.position : f1.feature.position;
    const f2Position = f2['@type'] === 'Feature' ? f2.position : f2.feature.position;
    return f1Position > f2Position ? 1 : f1Position < f2Position ? -1 : 0;
  });
  const featureItems = mergedFeatures
    // Sort standard features first
    .sort((f1, f2) => {
      const f1Standard = f1['@type'] === 'VersionFeature' && f1.standard;
      const f2Standard = f2['@type'] === 'VersionFeature' && f2.standard;
      return f1Standard ? -1 : f2Standard ? 1 : 0;
    })
    .map((fi, index) => {
    const nodeRef = createRef();
    const featureJsx = fi['@type'] === 'Feature' ? <Feature feature={fi}/> : <Feature versionFeature={fi}/>;
    return (
      <CSSTransition key={fi['@id']} nodeRef={nodeRef} timeout={500 + (index * 100)} classNames="feature-item" style={{ transitionDelay: `${(index + 1) * 100}ms` }} exit={false} appear={true} in={true}>
        <li ref={nodeRef}>{featureJsx}</li>
      </CSSTransition>
    );
  });

  const highlights = version.product.highlights.map(h => (<li key={h.id}><Highlight highlight={h}/></li>));

  const featuresJsx = featureItems.length > 0 ? null : (
    <>
      <ul className='sidebar-main-highlights'>
        {highlights}
      </ul>
      <button onClick={() => dispatch(nextCategory())} className='btn btn-secondary btn-outlined btn-start'>
        {t('btn.configure')}
        <FontAwesomeIcon icon={faArrowRight}/>
      </button>
    </>
  );

  const stepNav = currentCategory !== null ? (
    <MediaQuery maxDeviceWidth={992}>
      <Nav/>
    </MediaQuery>
  ) : null;

  const restart = () => {
    dispatch(selectFeature(null));
    dispatch(setCurrentStep(null));
    dispatch(setCurrentCategory(null));
  };

  const handleMyOptionsClick = () => {
    dispatch(toggleBasketVisible());
    if (!myOptionsClicked) {
      // Send event to Google Analytics
      triggerGaEvent('event', 'see options');
      setMyOptionsClicked(true);
    }
  };

  return (
    <aside className='sidebar'>
      <div className="sidebar-top-buttons">
        <a href='https://www.windelo-catamaran.com' target='_blank' rel='noreferrer' className='btn btn-secondary'>
          <FontAwesomeIcon icon={faGlobeEurope}/>
          {t('btn.website')}
        </a>
        {/*
          <Link to={'/' + language} className='btn btn-secondary'>
            <FontAwesomeIcon icon={faShip}/>
            {t('btn.changeBoat')}
          </Link>
        */}
      </div>
      <MediaQuery maxDeviceWidth={992}>
        <NavBar/>
      </MediaQuery>
      <div className="sidebar-content">
        <div className="sidebar-header">
          <h1 onClick={() => restart()}>{product.name} {version.name}</h1>
          <div className='product-starting-price'>
            {t('label.startingPrice')}&nbsp;<Price value={version.price ?? product.price}/>
          </div>
          <div className='product-price'>
            {t('label.withFeaturesPrice')}
            <span className="total-price"><Price value={totalPrice(version, userFeatures)}/></span>
          </div>
          {stepNav}
        </div>
        <TransitionGroup component='ul' className='sidebar-features'>
          {featureItems}
        </TransitionGroup>
        {featuresJsx}
        <div className="footer-buttons">
          <button className="btn btn-secondary btn-display-basket" onClick={handleMyOptionsClick}>
            {t('btn.myOptions')}
          </button>
          <BtnLanguage className="btn btn-secondary btn-language"/>
        </div>
      </div>
    </aside>
  );
}
