import React  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {toggleModal, confirmIncompatibleFeature} from '../../configuratorSlice';

export default function IncompatibilitiesModal(props) {
  const incompatibilities = useSelector(state => state.configurator.modals.incompatibilities.items);
  const show = useSelector(state => state.configurator.modals.incompatibilities.show);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const incompatibilitiesJsx = incompatibilities.map(f => <li key={f['@id']}>{f.name}</li>);

  const actions = (
    <>
      <button className="btn btn-outlined" onClick={() => dispatch(toggleModal('incompatibilities'))}>{t('modal.incompatibleFeatures.btn.cancel')}</button>
      <button className="btn" onClick={() => dispatch(confirmIncompatibleFeature())}>{t('modal.incompatibleFeatures.btn.confirm')}</button>
    </>
  );

  return (
    <Modal title={t('modal.incompatibleFeatures.title')} show={show} actions={actions} onClose={() => dispatch(toggleModal('incompatibilities'))}>
      <p>{t('modal.incompatibleFeatures.incompatibleWith')}</p>
      <ul>
        {incompatibilitiesJsx}
      </ul>
      <p>{t('modal.incompatibleFeatures.removeOptions')}</p>
    </Modal>
  );
}
