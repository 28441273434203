import {createSlice, createSelector, createAsyncThunk} from '@reduxjs/toolkit';
import ApiService from '../../app/services/api.service';
import i18n from '../../i18n';
import {crossFilter, crossExcludeFilter, triggerGaEvent} from '../../app/utils';

export const fetchVersion = createAsyncThunk(
  'version/fetch',
  async (slug, { getState }) => {
    return await ApiService.query('versions', '', {
      params: { 'slug': slug },
      headers: {
        'X-LOCALE': getState().configurator.language === 'fr' ? 'fr_FR' : 'en_GB'
      }
    });
  }
);

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async (arg, { getState }) => {
    return await ApiService.query('categories?exists[parent]=false', '', {
      headers: {
        'X-LOCALE': getState().configurator.language === 'fr' ? 'fr_FR' : 'en_GB'
      }
    });
  }
);

export const saveQuote = createAsyncThunk(
  'quotes/save',
  async (args, { getState }) => {
    let data = {
      ...getState().configurator.modals.checkout.form,
      version: getState().configurator.version['@id'],
    };
    if (!getState().configurator.modals.login.show) {
      const userFeatures = getState().configurator.userFeatures;
      data.features = userFeatures.map(f => f['@id']);
    }
    let url = 'quotes';
    if (args && args.email) {
      url += '?email=1';
    }
    if (data.orderDate === '') {
      data.orderDate = null;
    }
    if (data.deliveryDate === '') {
      data.deliveryDate = null;
    }
    return await ApiService.post(url, data, {
      headers: {
        'X-LOCALE': getState().configurator.language === 'fr' ? 'fr_FR' : 'en_GB'
      }
    });
  }
);

const initialState = {
  language: 'fr',
  loading: {
    product: false,
    categories: false
  },
  modals: {
    incompatibilities: {
      show: false,
      feature: null,
      items: []
    },
    requirements: {
      show: false,
      feature: null,
      items: [],
      operation: 'add'
    },
    login: {
      show: true
    },
    checkout: {
      show: false,
      loading: false,
      errors: {
        global: [],
        firstName: [],
        lastName: [],
        email: [],
        country: [],
        comment: [],
        deliveryDate: [],
        orderDate: [],
      },
      form: {
        gender: 'male',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        comment: '',
        deliveryDate: '',
        orderDate: '',
      }
    },
    checkoutSuccess: {
      show: false
    }
  },
  basketVisible: false,
  product: null,
  version: null,
  versionSlug: null,
  categories: [],
  currentStep: null, // Main category
  currentCategory: null, // Sub category
  features: [],
  userFeatures: [],
  selectedFeature: null
};

function getCurrentFeatures(state) {
  const currentStep = state.categories[state.currentStep];
  const currentCategory = state.currentCategory;

  if (currentStep === null || currentCategory === null) {
    return [];
  }

  return state.product.features.filter(f => f.category === currentStep['@id'] || (currentCategory && f.category === currentCategory['@id']));
}

export const configuratorSlice = createSlice({
  name: 'configurator',
  initialState: initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
      if (typeof state.categories[state.currentStep] !== 'undefined') {
        state.currentCategory = state.categories[state.currentStep].children[0];
        state.features = getCurrentFeatures(state);
      }
    },
    nextCategory: state => {
      let currentCategoryIndex = 0;
      if (state.currentStep === null) {
        state.currentStep = 0;
        state.currentCategory = state.categories[state.currentStep].children[0];
      } else {
        currentCategoryIndex = state.categories[state.currentStep].children.findIndex(c => c.id === state.currentCategory.id);
        if (state.categories[state.currentStep].children.length > currentCategoryIndex + 1) {
          state.currentCategory = state.categories[state.currentStep].children[currentCategoryIndex + 1];
        } else {
          // Send event to Google Analytics on next main category
          triggerGaEvent('event', 'step ' + state.categories[state.currentStep].name);

          state.currentStep = state.currentStep + 1;
          state.currentCategory = state.categories[state.currentStep].children[0];
        }
      }
      state.features = getCurrentFeatures(state);
    },
    previousCategory: state => {
      const currentCategoryIndex = state.categories[state.currentStep].children.findIndex(c => c.id === state.currentCategory.id);
      if (currentCategoryIndex - 1 >= 0) {
        // There is a previous category in current step
        state.currentCategory = state.categories[state.currentStep].children[currentCategoryIndex - 1];
      } else if (state.currentStep - 1 >= 0) {
        // There is no previous category in current step but there is a previous step
        state.currentStep = state.currentStep - 1;
        state.currentCategory = state.categories[state.currentStep].children[state.categories[state.currentStep].children.length - 1];
      } else {
        // There is no previous category nor previous step, display boat informations
        state.currentStep = null;
        state.currentCategory = null;
      }
      if (state.currentCategory !== null) {
        state.features = getCurrentFeatures(state);
      }
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
      if (action.payload === null) {
        state.currentStep = null;
        state.features = [];
      } else {
        const currentStep = state.categories.findIndex(c => c.children.some(child => child['@id'] === action.payload['@id']));
        state.currentStep = currentStep >= 0 ? currentStep : null;
        if (action.payload !== null) {
          state.features = getCurrentFeatures(state);
        }
      }
    },
    setVersionSlug: (state, action) => {
      state.versionSlug = action.payload;
    },
    addUserFeature: (state, action) => {
      const incompatibilities = crossFilter(state.userFeatures, action.payload.incompatibleFeatures);
      const requirements = crossExcludeFilter(state.userFeatures, action.payload.requiredFeatures);
      // Check if feature is incompatible with another selected feature
      if (incompatibilities.length > 0) {
        state.modals.incompatibilities.feature = action.payload;
        state.modals.incompatibilities.items = incompatibilities;
        state.modals.incompatibilities.show = true;
      } else if(requirements.length > 0) {
        state.modals.requirements.feature = action.payload;
        state.modals.requirements.items = requirements;
        state.modals.requirements.show = true;
        state.modals.requirements.operation = 'add';
      } else {
        state.userFeatures.push(action.payload);
      }
    },
    confirmIncompatibleFeature: (state, action) => {
      // Add selected feature and remove incompatible ones
      state.userFeatures.push(state.modals.incompatibilities.feature);
      state.userFeatures = state.userFeatures.filter(f1 => !state.modals.incompatibilities.items.find(f2 => f1['@id'] === f2['@id']));
      state.modals.incompatibilities = initialState.modals.incompatibilities;
    },
    confirmRequiredFeature: (state, action) => {
      if (state.modals.requirements.operation === 'add') {
        // Add selected feature and also required ones
        state.userFeatures = [
          ...state.userFeatures,
          state.modals.requirements.feature,
          ...state.modals.requirements.items
        ]
      } else {
        // Remove selected feature and also the ones required by it
        state.userFeatures = state.userFeatures.filter(f => f['@id'] !== state.modals.requirements.feature['@id']);
        state.userFeatures = state.userFeatures.filter(f => !state.modals.requirements.items.find(rf => rf['@id'] === f['@id']));
      }
      state.modals.requirements = initialState.modals.requirements;
    },
    removeUserFeature: (state, action) => {
      // Check if feature is required by another selected feature
      const required = state.userFeatures.filter(f => f.requiredFeatures?.some(rf => rf['@id'] === action.payload['@id']));
      if (required.length > 0) {
        state.modals.requirements.feature = action.payload;
        state.modals.requirements.items = required;
        state.modals.requirements.show = true;
        state.modals.requirements.operation = 'remove';
      } else {
        state.userFeatures = state.userFeatures.filter(f => f.id !== action.payload.id);
      }
    },
    selectFeature: (state, action) => {
      state.selectedFeature = action.payload;
    },
    toggleBasketVisible: (state, action) => {
      state.basketVisible = !state.basketVisible;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      i18n.changeLanguage(action.payload);
    },
    toggleModal: (state, action) => {
      state.modals[action.payload].show = !state.modals[action.payload].show;
      if (action.payload === 'checkout') {
        state.currentStep = null;
        state.currentCategory = null;
        state.features = [];
      }
    },
    handleCheckoutFormInputChange: (state, action) => {
      state.modals.checkout.form[action.payload.name] = action.payload.value;
    },
    setQuote: (state, action) => {
      state.modals.checkout.form = {
        ...state.modals.checkout.form,
        ...action.payload
      };
      state.modals.login.show = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVersion.pending, (state) => {
      state.loading.product = true;
    })
    builder.addCase(fetchVersion.fulfilled, (state, action) => {
      const version = action.payload[0];
      state.product = version.product;
      state.version = version;
      // Add standard and pre-selected features to userFeatures list on first load
      if (state.userFeatures.length === 0) {
        state.userFeatures = state.version
          .features.filter(vf => vf.standard || vf.preSelected)
          .map(vf => vf.feature)
        ;
      } else {
        // Reload features and userFeatures for translations
        state.features = getCurrentFeatures(state);
        state.userFeatures = state.userFeatures.map(uf => state.product.features.find(f => uf['@id'] === f['@id']));
      }
      state.loading.product = false;
    })
    builder.addCase(fetchVersion.rejected, (state) => {
      state.loading.product = false;
    })
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading.categories = true;
    })
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload
        .filter(c => c.children.length > 0) // Only categories with sub categories
        .map(c => {
          c.children = c.children.filter(child => state.version.product.features.findIndex(f => f.category === child['@id']) >= 0);
          return c;
        }) // Only sub categories with features
        // .filter(c => version.features.findIndex(vf => vf.feature.category.id === c.id && vf.unavailable) === -1) // Only categories not excluded in current version;
      state.loading.categories = false;
    })
    builder.addCase(fetchCategories.rejected, (state) => {
      state.loading.categories = false;
    })
    builder.addCase(saveQuote.pending, (state) => {
      state.modals.checkout.loading = true;
    })
    builder.addCase(saveQuote.fulfilled, (state) => {
      if (state.modals.login.show) {
        state.modals.login.show = false;
      } else {
        state.modals.checkout.show = false;
        state.modals.checkoutSuccess.show = true;
      }
      state.modals.checkout.errors = initialState.modals.checkout.errors;
      state.modals.checkout.loading = false;
      // Update local storage
      localStorage.setItem('quote', JSON.stringify(state.modals.checkout.form));
    })
    builder.addCase(saveQuote.rejected, (state) => {
      state.modals.checkout.errors = JSON.parse(JSON.stringify(initialState.modals.checkout.errors)); // Deep cloning initialState
      const required = ['firstName', 'lastName', 'email', 'deliveryDate', 'orderDate'];
      for (const [key, value] of Object.entries(state.modals.checkout.form)) {
        if (required.includes(key) && value === '') {
          state.modals.checkout.errors[key].push('required');
        }
      }
      if (!Object.entries(state.modals.checkout.errors).reduce((hasError, [key, value]) => hasError || value.length > 0, false)) {
        state.modals.checkout.errors.global.push('default');
      }
      state.modals.checkout.loading = false;
    })
  },
});

const getCategories = state => state.configurator.categories;
const getCurrentStep = state => state.configurator.currentStep;
const getCurrentCategory = state => state.configurator.currentCategory;
export const selectHasNextCategory = createSelector(
  [getCategories, getCurrentStep, getCurrentCategory],
  (categories, currentStep, currentCategory) => {
    if (categories.length > 0 && currentStep !== null && currentCategory !== null) {
      const currentCategoryIndex = categories[currentStep].children.findIndex(c => c.id === currentCategory.id);
      return currentStep < categories.length - 1 || currentCategoryIndex < categories[currentStep].children.length - 1;
    }
    return true;
  }
);
export const selectHasPreviousCategory = createSelector([getCurrentStep], currentStep => currentStep !== null);

export const { setCurrentStep, nextCategory, previousCategory, setVersionSlug, setCurrentCategory, addUserFeature, confirmIncompatibleFeature, confirmRequiredFeature, removeUserFeature, selectFeature, toggleBasketVisible, setLanguage, toggleModal, handleCheckoutFormInputChange, setQuote } = configuratorSlice.actions;

export default configuratorSlice.reducer;
