import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/free-solid-svg-icons';
import Slide from './slide/Slide';
import Nav from './nav/Nav';
import placeholder from '../../../assets/images/feature-placeholder.jpg';
import './Gallery.scss';

export default function Gallery(props) {
  const {feature} = props;
  const { t } = useTranslation();
  const version = useSelector(state => state.configurator.version);
  const versionFeature = version.features.find(vf => feature && vf.feature.id === feature.id);

  let images = [];
  if (feature !== null && feature.images.length > 0) {
    images = feature.images.filter((image, index, array) => array.findIndex(i => i['@id'] === image['@id']) === index); // Remove duplicates return by the API
  }
  if (versionFeature) {
    images.push(...versionFeature.images);
  }
  if (feature !== null && images.length === 0) {
    images.push(placeholder);
  }

  const slides = images.length > 0 ?
    images.map((i, index) => <Slide key={i['@id'] ?? index} image={i}/>)
    : <img src={process.env.REACT_APP_MEDIA_ENTRYPOINT + version.image.contentUrl} alt={version.name}/>;

  const featureUrl = feature?.url ? (
    <a href={feature.url} className="btn btn-secondary btn-sm btn-more-info" target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faExternalLink}/>
      {t('btn.moreInfo')}
    </a>
  ) : null;

  return (
    <div className='gallery-container'>
      <div className='slider-container'>
        <Slider>
          {slides}
        </Slider>
        <footer className='slider-footer'>
          {featureUrl}
        </footer>
      </div>
      <Nav/>
    </div>
  );
}
