import React from 'react';
import {useTranslation} from 'react-i18next';

export default function FormFieldErrors(props) {
  const {t} = useTranslation();

  const errors = props.errors.map(err => (<p key={err} className="form-error">{t('error.' + err)}</p>))

  return (
    <div>{errors}</div>
  );
}
