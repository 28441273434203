import React from 'react';
import {ThreeDots} from 'react-loader-spinner';
import './Loader.scss';

export default function Loader() {
  return (
    <div className="Loader">
      <ThreeDots color='#5ec059'/>
    </div>
  );
}
