import React  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/Modal';
import {toggleModal, confirmRequiredFeature} from '../../configuratorSlice';

export default function RequirementsModal() {
  const requirements = useSelector(state => state.configurator.modals.requirements.items);
  const show = useSelector(state => state.configurator.modals.requirements.show);
  const operation = useSelector(state => state.configurator.modals.requirements.operation);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationsPath = 'modal.requiredFeatures.' + operation;

  const requirementsJsx = requirements.map(f => <li key={f['@id']}>{f.name}</li>);

  const actions = (
    <>
      <button className="btn btn-outlined" onClick={() => dispatch(toggleModal('requirements'))}>{t(translationsPath + '.btn.cancel')}</button>
      <button className="btn" onClick={() => dispatch(confirmRequiredFeature())}>{t(translationsPath + '.btn.confirm')}</button>
    </>
  );

  return (
    <Modal title={t(translationsPath + '.title')} show={show} actions={actions} onClose={() => dispatch(toggleModal('requirements'))}>
      <p>{t(translationsPath + '.label')}</p>
      <ul>
        {requirementsJsx}
      </ul>
      <p>{t(translationsPath + '.question')}</p>
    </Modal>
  );
}
