import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeUserFeature } from '../../configuratorSlice';
import Price from '../../../../components/Price';
import './Feature.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export default function Feature(props) {
  const {feature} = props;
  const version = useSelector(state => state.configurator.version);
  const dispatch = useDispatch();
  const versionFeature = version.features.find(vf => vf.feature['@id'] === feature['@id'])
  const standard = versionFeature ? versionFeature.standard : false;

  return (
    <div className="basket-feature">
      <span className="feature-text">
        <span className="feature-name">{feature.name}</span>
        <span className='feature-price'>
          <Price value={feature.price}/>
        </span>
      </span>
      <button className="btn" disabled={standard} onClick={() => dispatch(removeUserFeature(feature))}>
        <FontAwesomeIcon icon={faTimes}/>
      </button>
    </div>
  );
}
