import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {saveQuote} from '../configuratorSlice';
import FormFieldErrors from '../../../components/FormFieldErrors';
import LoginFormFields from "./LoginFormFields";

export default function LoginForm() {
  const errors = useSelector(state => state.configurator.modals.checkout.errors);
  const dispatch = useDispatch();

  return (
    <form className="login-form" onSubmit={() => dispatch(saveQuote({ email: true }))}>
      <LoginFormFields/>
      <FormFieldErrors errors={errors.global}/>
    </form>
  );
}
