import React  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/Modal';
import {toggleModal} from '../configuratorSlice';

export default function CheckoutSuccessModal(props) {
  const show = useSelector(state => state.configurator.modals.checkoutSuccess.show);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const closeModal = () => dispatch(toggleModal('checkoutSuccess'));

  const actions = (
    <>
      <button className="btn btn-outlined" onClick={closeModal}>
        {t('modal.checkoutSuccess.btn.close')}
      </button>
    </>
  );

  return (
    <Modal title={t('modal.checkoutSuccess.title')} show={show} actions={actions} onClose={closeModal}>
      <p>{t('modal.checkoutSuccess.message')}</p>
    </Modal>
  );
}
