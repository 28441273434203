import React from 'react';
import {useSelector} from 'react-redux';
import {selectHasNextCategory} from '../../configuratorSlice';
import BtnPrevious from './BtnPrevious';
import BtnNext from './BtnNext';
import BtnQuote from './BtnQuote';
import './Nav.scss';

function Nav() {
  const hasNextCategory = useSelector(selectHasNextCategory);

  return (
    <div className='category-nav'>
      <BtnPrevious/>
      {hasNextCategory ? <BtnNext/> : <BtnQuote/>}
    </div>
  );
}

export default Nav;
