import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

function Slide(props) {
  const { image } = props;
  const url = image['@type'] === 'https://schema.org/MediaObject' ? process.env.REACT_APP_API_ENTRYPOINT + image['contentUrl'] : image;
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    let img = new Image();

    img.onload = function(){
      setHeight(this.height);
      setWidth(this.width);
    };

    img.src = url;
  }, [url]);

  if (image.sphere) {
    return <ReactPhotoSphereViewer src={url} height="100%" width="100%"/>
  }

  if (width !== null && height !== null) {
    return <img src={url} alt=""/>;
  }

  return null;
}

Slide.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default Slide;
