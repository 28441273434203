import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setLanguage} from '../../configuratorSlice';

export default function BtnLanguage(props) {
  const {className} = props;
  const language = useSelector(state => state.configurator.language);
  const dispatch = useDispatch();
  const flag = language === 'fr' ? '🇬🇧' : '🇫🇷';

  return (
    <button className={className} onClick={() => dispatch(setLanguage(language === 'fr' ? 'en' : 'fr'))}>
      {flag}
    </button>
  );
}
