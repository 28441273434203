import {useId} from "react";
import './Switch.scss';

export default function Switch(props) {
  const id = useId();
  const {size} = props;
  let inputProps = {...props};
  let className = 'switch';

  if (size) {
    className += ` switch--${size}`;
    delete inputProps.size;
  }

  return (
    <label htmlFor={id} className={className}>
      <input type="checkbox" id={id} {...props}/>
      <span className="slider"/>
    </label>
  )
}
