import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {handleCheckoutFormInputChange} from '../configuratorSlice';
import FormFieldErrors from '../../../components/FormFieldErrors';
import CountrySelect from "../../../components/CountrySelect";

export default function LoginFormFields() {
  const form = useSelector(state => state.configurator.modals.checkout.form);
  const errors = useSelector(state => state.configurator.modals.checkout.errors);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleInputChange = event => {
    dispatch(handleCheckoutFormInputChange({
      name: event.target.name,
      value: event.target.value
    }));
  };

  return (
    <>
      <div className="form-row">
        <label htmlFor="gender">{t('modal.checkout.form.gender')}</label>
        <select name="gender" id="gender" value={form.gender} onChange={e => handleInputChange(e)}>
          <option value="male">{t('modal.checkout.form.genders.male')}</option>
          <option value="female">{t('modal.checkout.form.genders.female')}</option>
        </select>
      </div>
      <div className="form-row">
        <label htmlFor="lastName">{t('modal.checkout.form.lastName')}</label>
        <div className="form-field">
          <input type="text" id="lastName" name="lastName" value={form.lastName} onChange={e => handleInputChange(e)} placeholder={t('modal.checkout.form.lastName')} required/>
          <FormFieldErrors errors={errors.lastName}/>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="firstName">{t('modal.checkout.form.firstName')}</label>
        <div className="form-field">
          <input type="text" id="firstName" name="firstName" value={form.firstName} onChange={e => handleInputChange(e)} placeholder={t('modal.checkout.form.firstName')} required/>
          <FormFieldErrors errors={errors.firstName}/>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="email">{t('modal.checkout.form.email')}</label>
        <div className="form-field">
          <input type="email" id="email" name="email" value={form.email} onChange={e => handleInputChange(e)} placeholder={t('modal.checkout.form.email')} required/>
          <FormFieldErrors errors={errors.email}/>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="country">{t('modal.checkout.form.country')}</label>
        <div className="form-field">
          <CountrySelect id="country" name="country" defautValue="" defaultLabel={t('modal.checkout.form.selectCountry')} value={form.country} onChange={e => handleInputChange(e)} required/>
          <FormFieldErrors errors={errors.country}/>
        </div>
      </div>
    </>
  );
}
