import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import { Provider } from 'react-redux';
import Loader from './components/Loader';
import './index.css';
import App from './App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import { install } from 'ga-gtag';
import Products from "./features/products/Products";
import Configurator from "./features/configurator/Configurator";

install(process.env.REACT_APP_GA_TRACKING_ID);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: '/:language',
        element: (
          <Suspense fallback={<Loader/>}>
            <Products/>
          </Suspense>
        )
      },
      {
        path: '/:language/:productSlug/:versionSlug',
        element: (
          <Suspense fallback={<Loader/>}>
            <Configurator/>
          </Suspense>
        )
      }
    ]
  }
]);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
