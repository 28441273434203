import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation, Trans} from 'react-i18next';
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Price from '../../components/Price';
import './ProductItemSlide.scss';

function ProductItemSlide(props) {
  const { product, version } = props;
  const { t } = useTranslation();
  const language = useSelector(state => state.configurator.language);

  return (
    <div key={version.id} className="product-card-version">
      <div className="product-card-image-container">
        <img src={version.image ? process.env.REACT_APP_MEDIA_ENTRYPOINT + version.image.contentUrl : ''} alt={version.name}/>
        <div className="product-card-title">
          <h3>{product.name}</h3>
          <h4>{version.name}</h4>
        </div>
      </div>
      <p>
        <Trans i18nKey='label.priceFrom'>
          A partir de <Price value={version.price}/> HT
        </Trans>
      </p>
      <Link to={'/' + language + '/' + version.slug} className="btn btn-outlined btn-secondary">
        {t('btn.chooseVersion')}
        <FontAwesomeIcon icon={faArrowRight}/>
      </Link>
    </div>
  );
}

ProductItemSlide.propTypes = {
  product: PropTypes.object,
  version: PropTypes.object
};

export default ProductItemSlide;
