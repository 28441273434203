import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import './Modal.scss';

function Modal(props) {
  const {children, title, actions, show, onClose} = props;
  const modalNodeRef = useRef(null);
  const overlayNodeRef = useRef(null);

  const closeIcon = typeof onClose === 'function' ? <FontAwesomeIcon icon={faTimes} onClick={() => onClose()} className="modal-close"/> : null;

  return (
    <>
      <CSSTransition nodeRef={overlayNodeRef} appear={true} in={show} unmountOnExit={true} timeout={1000} classNames="modal-overlay">
        <div ref={overlayNodeRef} className="modal-overlay"></div>
      </CSSTransition>

      <CSSTransition nodeRef={modalNodeRef} appear={true} in={show} unmountOnExit={true} timeout={1000} classNames="modal-container">
        <div ref={modalNodeRef} className="modal-container" onClick={() => onClose()}>
          <div className="modal" onClick={e => e.stopPropagation()}>
            <header className="modal-header">
              <h3>{title}</h3>
              {closeIcon}
            </header>
            <div className="modal-content">
              {children}
            </div>
            <footer className="modal-actions">
              {actions}
            </footer>
          </div>
        </div>
      </CSSTransition>

    </>
  );
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actions: PropTypes.element,
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default Modal;
