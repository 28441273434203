import React from 'react';
import PropTypes from 'prop-types';
import {removeDuplicates} from '../../../../app/utils';
import './FeatureList.scss';

function FeatureList(props) {
  const {title, features} = props;

  if (features.length === 0) {
    return null;
  }

  const featuresItems = removeDuplicates(features).map(f => <li key={f['@id']}><span className="label">{f.name}</span></li>);

  return (
    <div className="feature-list">
      <h5>{title}</h5>
      <ul className="features">
        {featuresItems}
      </ul>
    </div>
  );
}

FeatureList.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array
};

export default FeatureList;
