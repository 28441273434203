import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {triggerGaEvent} from '../../../../app/utils';
import {toggleModal} from '../../configuratorSlice';

function BtnQuote() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleModal('checkout'));
    // Send event to Google Analytics
    triggerGaEvent('event', 'quote open end');
  };

  return (
    <button onClick={handleClick} className='btn btn-secondary btn-quote'>
      {t('btn.step.quote')}
      <div className="btn-icon">
        <FontAwesomeIcon icon={faEnvelope}/>
      </div>
    </button>
  );
}

export default BtnQuote;
