import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Products.scss';
import { fetchProducts } from './productsSlice';
import Loader from '../../components/Loader';
import ProductItem from "./ProductItem";

export default function Products() {
  const products = useSelector(state => state.products.items);
  const loading = useSelector(state => state.products.loading);
  const language = useSelector(state => state.configurator.language);
  const dispatch = useDispatch();

  useEffect(() => {
    if (language) {
      dispatch(fetchProducts());
    }
  }, [dispatch, language]);

  if (loading) {
    return <Loader/>;
  }

  const productsJsx = products.map(p => <ProductItem key={p.id} product={p}/>);

  return (
    <div className="products-container">
      <section className="products-grid">
        {productsJsx}
      </section>
    </div>
  );
}
