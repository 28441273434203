import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './ProductItem.scss';
import ProductItemSlide from "./ProductItemSlide";
import Highlight from "../../components/Highlight";

function ProductItem(props) {
  const { product } = props;

  const slides = product.versions.map(v => <ProductItemSlide key={v.id} product={product} version={v}/>);
  const hightlights = product.highlights.map(h => (
    <li key={h.id}>
      <Highlight highlight={h}/>
    </li>
  ));

  return (
    <article className="product-card">
      <header className="product-card-header">
        <div>
          <h2 className="product-card-title">{product.name}</h2>
          <p className="product-card-subtitle">{product.description}</p>
        </div>
        <ul className="product-card-highlights">
          {hightlights}
        </ul>
      </header>
      <Slider dots={true}>
        {slides}
      </Slider>
    </article>
  );
}

ProductItem.propTypes = {
  product: PropTypes.object
};

export default ProductItem;
