import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faEdit} from '@fortawesome/free-solid-svg-icons';
import {Collapse} from '@kunukn/react-collapse';
import {setCurrentCategory} from '../../configuratorSlice';
import Feature from '../feature/Feature';
import './Category.scss';

export default function Category(props) {
  const {category} = props;
  const userFeatures = useSelector(state => state.configurator.userFeatures);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const isMain = typeof category.parent === 'undefined';

  const name = isMain ? (
    <h4>
      {category.name}
      <FontAwesomeIcon icon={faChevronDown} className={isOpen ? 'open' : ''} onClick={() => setIsOpen(!isOpen)}/>
    </h4>
  ) : (
    <h5>
      {category.name}
      <FontAwesomeIcon icon={faEdit} onClick={() => dispatch(setCurrentCategory(category))}/>
    </h5>
  );

  const categories = category.children.map(c => <Category key={c['@id']} category={c}/>);

  const features = userFeatures.filter(feature => feature.category === category['@id']).map(feature => (
    <li key={feature['@id']}>
      <Feature feature={feature}/>
    </li>
  ));

  return (
    <li key={category['@id']} className="basket-category">
      {name}
      <Collapse isOpen={isOpen || !isMain}>
        <ul>
          {categories}
        </ul>
      </Collapse>
      <Collapse isOpen={isOpen || !isMain}>
        <ul>
          {features}
        </ul>
      </Collapse>
    </li>
  );
}
