import React  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import Modal from '../../../components/Modal';
import CheckoutForm from './CheckoutForm';
import {toggleModal, saveQuote} from '../configuratorSlice';

export default function CheckoutModal(props) {
  const show = useSelector(state => state.configurator.modals.checkout.show);
  const loading = useSelector(state => state.configurator.modals.checkout.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const actions = (
    <>
      <button className="btn btn-outlined" onClick={() => dispatch(toggleModal('checkout'))}>
        {t('modal.checkout.btn.cancel')}
      </button>
      <button className="btn" onClick={() => dispatch(saveQuote({ email: true }))} disabled={loading}>
        {loading ? <FontAwesomeIcon icon={faSpinner} className="fa-pulse"/> : <FontAwesomeIcon icon={faEnvelope}/>}
        {t('modal.checkout.btn.confirm')}
      </button>
    </>
  );

  return (
    <Modal title={t('modal.checkout.title')} show={show} actions={actions} onClose={() => dispatch(toggleModal('checkout'))}>
      <CheckoutForm/>
    </Modal>
  );
}
