import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {handleCheckoutFormInputChange, saveQuote} from '../configuratorSlice';
import FormFieldErrors from '../../../components/FormFieldErrors';
import LoginFormFields from "../login/LoginFormFields";

export default function CheckoutForm() {
  const form = useSelector(state => state.configurator.modals.checkout.form);
  const errors = useSelector(state => state.configurator.modals.checkout.errors);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleInputChange = event => {
    dispatch(handleCheckoutFormInputChange({
      name: event.target.name,
      value: event.target.value
    }));
  };

  return (
    <form className="checkout-form" onSubmit={() => dispatch(saveQuote({ email: true }))}>
      <LoginFormFields/>
      <div className="form-row">
        <label htmlFor="comment">{t('modal.checkout.form.comment')}</label>
        <div className="form-field">
          <textarea name="comment" id="comment" value={form.comment} onChange={e => handleInputChange(e)} placeholder={t('modal.checkout.form.comment')}/>
          <FormFieldErrors errors={errors.comment}/>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="deliveryDate">{t('modal.checkout.form.deliveryDate')}</label>
        <div className="form-field">
          <input type="date" id="deliveryDate" name="deliveryDate" value={form.deliveryDate} onChange={e => handleInputChange(e)} required/>
          <FormFieldErrors errors={errors.deliveryDate}/>
        </div>
      </div>
      <div className="form-row">
        <label htmlFor="orderDate">{t('modal.checkout.form.orderDate')}</label>
        <div className="form-field">
          <input type="date" id="orderDate" name="orderDate" value={form.orderDate} onChange={e => handleInputChange(e)} required/>
          <FormFieldErrors errors={errors.orderDate}/>
        </div>
      </div>
      <FormFieldErrors errors={errors.global}/>
    </form>
  );
}
