import { configureStore } from '@reduxjs/toolkit';
import productsReducer from '../features/products/productsSlice';
import configuratorReducer from '../features/configurator/configuratorSlice';

export default configureStore({
  reducer: {
    products: productsReducer,
    configurator: configuratorReducer,
  },
});
