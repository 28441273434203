import React, {createRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { fetchCategories, setCurrentStep, setCurrentCategory } from '../configuratorSlice';
import './NavBar.scss';

export default function NavBar() {
  const categories = useSelector((state) => state.configurator.categories);
  const currentStep = useSelector((state) => state.configurator.currentStep);
  const currentCategory = useSelector((state) => state.configurator.currentCategory);
  const language = useSelector(state => state.configurator.language);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch, language]);

  const handleStepClick = (event, index) => {
    event.preventDefault();
    dispatch(setCurrentStep(index));
  }

  const handleCategoryClick = (event, category) => {
    event.preventDefault();
    dispatch(setCurrentCategory(category));
  }

  const steps = categories.map((c, index) => (
    <li key={c.id} className={currentStep === index ? 'active' : ''}>
      <button href="#" onClick={event => handleStepClick(event, index)}>
        {index + 1}. {c.name}
        <FontAwesomeIcon icon={faChevronRight} className={currentStep === index ? 'open' : ''}/>
      </button>
    </li>
  ));

  let subCategories = [];
  if (currentStep != null) {
    subCategories = categories[currentStep].children.map((c, index) => {
      const nodeRef = createRef();
      return (
        <CSSTransition key={c['@id']} nodeRef={nodeRef} className={c === currentCategory ? 'active' : ''} timeout={500 + (index * 100)} style={{ transitionDelay: `${(index + 1) * 100}ms` }} exit={false} appear={true} in={true}>
          <li key={c.id} ref={nodeRef}>
            <button href="#" onClick={event => handleCategoryClick(event, c)}>{c.name}</button>
          </li>
        </CSSTransition>
      );
    });
  }
  const subMenu = subCategories.length > 0 ? (
    <TransitionGroup component='ul' className='sub-menu'>
      {subCategories}
    </TransitionGroup>
  ) : null;

  return (
    <header className='navbar'>
      <nav>
        <ul>
          {steps}
        </ul>
      </nav>
      <nav>
        {subMenu}
      </nav>
    </header>
  );
}
