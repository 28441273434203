import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {selectHasNextCategory, nextCategory} from '../../configuratorSlice';

function BtnNext() {
  const { t } = useTranslation();
  const hasNextCategory = useSelector(selectHasNextCategory);
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(nextCategory())} disabled={!hasNextCategory} className='btn btn-primary btn-next'>
      {t('btn.step.next')}
      <div className="btn-icon">
        <FontAwesomeIcon icon={faArrowRight}/>
      </div>
    </button>
  );
}

export default BtnNext;
