import {gtag} from 'ga-gtag';

/**
 * Remove duplicates based on API Platform @id
 * @param {array} values Array of values to remove duplicates from
 * @returns {array} Array without duplicates @id
 */
export function removeDuplicates(values) {
  return values.filter((v1, index, features) => features.findIndex(v2 => v2['@id'] === v1['@id']) === index)
}

/**
 * Return every elements included in both array1 and array2
 * @param {array} array1
 * @param {array} array2
 * @returns {array} Array of similar values between array1 and array2
 */
export function crossFilter(array1, array2) {
  return array1.filter(v1 => array2.some(v2 => v1['@id'] === v2['@id']));
}

/**
 * Return every elements NOT included in array1 but included in array2
 * @param {array} array1
 * @param {array} array2
 * @returns {array} Array of values not included in array1
 */
export function crossExcludeFilter(array1, array2) {
  const included = crossFilter(array1, array2);
  return removeDuplicates(array2.filter(v2 => !included.some(v1 => v2['@id'] === v1['@id'])));
}

/**
 * Return product total price (version price + user features prices)
 * @param version Selected version
 * @param userFeatures Selected features
 * @returns float Total price
 */
export function totalPrice(version, userFeatures) {
  return parseFloat(version.price) + userFeatures
    .filter(f => !version.features.some(vf => vf.standard && vf.feature['@id'] === f['@id']))
    .reduce((accumulator, f) => accumulator + parseFloat(f.price ?? 0), 0)
  ;
}

export function triggerGaEvent(action, label, options) {
  label = slugify(`config ${label}`);
  if (process.env.NODE_ENV === 'development') {
    console.log('GA event: ', action, label, options);
  } else {
    gtag(action, label, options);
  }
}

function slugify(string) {
  return string
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
  ;
}
