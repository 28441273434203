import React from 'react';
import {useSelector} from "react-redux";

export default function Price(props) {
  const { value } = props;
  const language = useSelector((state) => state.configurator.language);

  if (isNaN(value)) {
    return null;
  }

  let price;
  if (language === 'fr') {
    price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
  } else {
    price = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' }).format(value);
  }

  return <span className='price'>{price}</span>;
}
