import React from 'react';
import PropTypes from "prop-types";
import "./Highlight.scss";

function Highlight(props) {
  const { highlight } = props;

  return (
    <div className="highlight">
      <i className={highlight.icon}/>
      <span>{highlight.name}</span>
    </div>
  );
}

Highlight.propTypes = {
  highlight: PropTypes.object
};

export default Highlight;
