import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {selectHasPreviousCategory, previousCategory} from '../../configuratorSlice';

function BtnPrevious() {
  const { t } = useTranslation();
  const hasPreviousCategory = useSelector(selectHasPreviousCategory);
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(previousCategory())} disabled={!hasPreviousCategory} className='btn btn-primary btn-prev'>
      <div className="btn-icon">
        <FontAwesomeIcon icon={faArrowLeft}/>
      </div>
      {t('btn.step.previous')}
    </button>
  );
}

export default BtnPrevious;
