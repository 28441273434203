import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Price from '../../../components/Price';
import CheckoutModal from './CheckoutModal';
import CheckoutSuccessModal from './CheckoutSuccessModal';
import {toggleBasketVisible, toggleModal} from "../configuratorSlice";
import {totalPrice, triggerGaEvent} from "../../../app/utils";
import Category from './category/Category';
import './Basket.scss';

export default function Basket() {
  const { t } = useTranslation();
  const product = useSelector(state => state.configurator.product);
  const version = useSelector(state => state.configurator.version);
  const basketVisible = useSelector(state => state.configurator.basketVisible);
  const categories = useSelector(state => state.configurator.categories);
  const userFeatures = useSelector(state => state.configurator.userFeatures);
  const dispatch = useDispatch();

  const categoriesJsx = categories.map(c => <Category key={c['@id']} category={c}/>);

  const handleCheckoutClick = () => {
    dispatch(toggleModal('checkout'));
    // Send event to Google Analytics
    triggerGaEvent('event', 'quote open');
  }

  return (
    <aside className={'basket ' + (basketVisible ? 'visible' : '')}>
      <div className="basket-container">
        <div className="basket-header">
          <FontAwesomeIcon icon={faTimes} onClick={() => dispatch(toggleBasketVisible())} className="basket-header__icon"/>
          <div className="basket-header__title">
            <h4>{product.name} {version.name}</h4>
            <p>
              <Trans i18nKey='label.priceFrom'>
                A partir de <Price value={version.price}/> HT
              </Trans>
            </p>
          </div>
        </div>
        <div className="basket-content">
          <ul className="basket-items">
            {categoriesJsx}
          </ul>
          <div className="basket-content-footer">
            <p>
              {t('label.priceTotal')}&nbsp;<Price value={totalPrice(version, userFeatures)}/>
            </p>
            <button className="btn btn-primary" onClick={handleCheckoutClick}>{t('btn.getQuote')}</button>
          </div>
        </div>
      </div>
      <CheckoutModal/>
      <CheckoutSuccessModal/>
    </aside>
  );
}
