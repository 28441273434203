import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ApiService from '../../app/services/api.service';

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async (arg, { getState }) => {
    return await ApiService.get('products', '', {
      headers: {
        'X-LOCALE': getState().configurator.language === 'fr' ? 'fr_FR' : 'en_GB'
      }
    });
  }
);

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    loading: false,
    items: [],
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading = false;
    })
    builder.addCase(fetchProducts.rejected, (state) => {
      state.loading = false;
    })
  },
});

export default productsSlice.reducer;
