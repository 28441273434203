import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import MediaQuery from 'react-responsive';
import {Collapse} from '@kunukn/react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faChevronDown, faImages} from '@fortawesome/free-solid-svg-icons';
import { addUserFeature, removeUserFeature, selectFeature } from '../../configuratorSlice';
import Price from '../../../../components/Price';
import Gallery from '../../gallery/Gallery';
import FeatureList from './FeatureList';
import placeholder from '../../../../assets/images/feature-placeholder.jpg';
import './Feature.scss';
import Switch from "../../../../components/Switch";

export default function Feature(props) {
  let { feature, versionFeature } = props;
  const { t } = useTranslation();

  if (versionFeature) {
    feature = versionFeature.feature;
  }
  const standard = versionFeature ? versionFeature.standard : false;
  const userFeatures = useSelector((state) => state.configurator.userFeatures);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const checked = userFeatures.findIndex(f => f.id === feature.id) >= 0;

  let featureImage = feature.images.length > 0 ? (
    <img src={process.env.REACT_APP_MEDIA_ENTRYPOINT + feature.images[0].contentUrl} alt="" onClick={() => dispatch(selectFeature(feature))}/>
  ) : null;
  if (featureImage === null) {
    if (versionFeature && versionFeature.images.length > 0) {
      featureImage = <img src={process.env.REACT_APP_MEDIA_ENTRYPOINT + versionFeature.images[0].contentUrl} alt="" onClick={() => dispatch(selectFeature(feature))}/>;
    }
  }

  const iconImage = featureImage ?
    <FontAwesomeIcon icon={faImages} onClick={() => dispatch(selectFeature(feature))}/>
    : null;

  const haveImage = featureImage !== null;

  if (!featureImage) {
    featureImage = <img src={placeholder} style={{ objectFit: 'contain' }} alt=""/>;
  }

  const haveDescription = typeof feature.description !== 'undefined' && feature.description !== '';
  const haveIncompatibilities = feature.incompatibleFeatures.length > 0;
  const haveRequirements = feature.requiredFeatures.length > 0;

  const iconOpen = haveDescription || haveImage || haveIncompatibilities || haveRequirements ? (
    <FontAwesomeIcon icon={faChevronDown} className={isOpen ? 'open' : ''} onClick={() => setIsOpen(!isOpen)}/>
  ) : null;

  const icons = (
    <div className="feature-options-icon">
      {iconImage}
      {iconOpen}
    </div>
  );

  const featureUrl = feature.url ? (
    <p className="feature-url">
      <a href={feature.url} target="_blank" rel="noreferrer">{t('btn.moreInfo')}</a>
    </p>
  ) : null;

  const handleCheckboxChange = event => {
    if (event.target.checked) {
      dispatch(addUserFeature(feature));
    } else {
      dispatch(removeUserFeature(feature));
    }
    dispatch(selectFeature(feature));
  }

  return (
    <article className="feature">
      <div className={'feature-card ' + (checked ? 'active' : '')}>
        <div className="feature-card-header">
          <div className="badge badge-check">
            <FontAwesomeIcon icon={faCheck}/>
          </div>
          <h5 className="feature-title" onClick={() => dispatch(selectFeature(feature))}>
            {feature.name}
          </h5>
          {/*
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" value={feature.id} checked={checked} disabled={standard} id={'feature-' + feature.id} onChange={handleCheckboxChange}/>
            <label htmlFor={'feature-' + feature.id} className="custom-control-label"></label>
          </div>
          */}
          <Switch size="small" value={feature.id} checked={checked} disabled={standard} onChange={handleCheckboxChange}/>
        </div>
        <div className="feature-options">
          <div className="feature-price"><Price value={feature.price}/></div>
          {icons}
        </div>
        <Collapse isOpen={isOpen} className={'feature-details ' + (isOpen ? 'open' : '')}>
          <div>
            <p className="feature-description">{feature.description}</p>
            {featureUrl}
            <FeatureList title={t('label.incompatibleOptions')} features={feature.incompatibleFeatures}/>
            <FeatureList title={t('label.requiredOptions')} features={feature.requiredFeatures}/>
            <MediaQuery minDeviceWidth={992}>
              {featureImage}
            </MediaQuery>
            <MediaQuery maxDeviceWidth={992}>
              <Gallery feature={feature}/>
            </MediaQuery>
          </div>
        </Collapse>
      </div>
    </article>
  );
}
