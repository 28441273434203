import React  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faCheck} from "@fortawesome/free-solid-svg-icons";
import {saveQuote} from '../configuratorSlice';
import Modal from '../../../components/Modal';
import LoginForm from "./LoginForm";

export default function LoginModal() {
  const show = useSelector(state => state.configurator.modals.login.show);
  const loading = useSelector(state => state.configurator.modals.checkout.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const actions = (
    <>
      <button className="btn btn-outlined" onClick={() => dispatch(saveQuote({ email: true }))} disabled={loading}>
        {loading ? <FontAwesomeIcon icon={faSpinner} className="fa-pulse"/> : <FontAwesomeIcon icon={faCheck}/>}
        {t('modal.login.btn.confirm')}
      </button>
    </>
  );

  return (
    <Modal title={t('modal.login.title')} show={show} actions={actions}>
      <LoginForm/>
    </Modal>
  );
}
