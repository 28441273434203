import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import MediaQuery from 'react-responsive'
import NavBar from './navbar/NavBar';
import SideBar from './sidebar/SideBar';
import Basket from './basket/Basket';
import Gallery from './gallery/Gallery';
import {fetchVersion, selectFeature, setCurrentCategory, setCurrentStep, setVersionSlug} from './configuratorSlice';
import IncompatibilitiesModal from './sidebar/feature/IncompatibilitiesModal';
import RequirementsModal from './sidebar/feature/RequirementsModal';
import Loader from '../../components/Loader';
import './Configurator.scss';
import {triggerGaEvent} from "../../app/utils";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";
import LoginModal from "./login/LoginModal";

export default function Configurator() {
  const { productSlug, versionSlug } = useParams();
  const product = useSelector(state => state.configurator.product);
  const version = useSelector(state => state.configurator.version);
  const selectedFeature = useSelector(state => state.configurator.selectedFeature);
  const loading = useSelector(state => state.configurator.loading);
  const language = useSelector(state => state.configurator.language);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setVersionSlug(versionSlug));
    dispatch(selectFeature(null));
    dispatch(setCurrentStep(null));
    dispatch(setCurrentCategory(null));
  }, [dispatch, productSlug, versionSlug]);

  useEffect(() => {
    dispatch(fetchVersion(productSlug + '/' + versionSlug));
  }, [dispatch, productSlug, versionSlug, language]);

  useEffect(() => {
    if (product && version) {
      // Send event to Google Analytics
      triggerGaEvent('event', 'open ' + product.name + ' ' + version.name);
    }
  }, [product, version]);

  if (loading.product || product === null) {
    return <Loader/>;
  }

  return (
    <div className="configurator-container">
      <Helmet>
        <title>{t('meta.product.title', { product, version })}</title>
        <meta name="description" content={version.description ?? t('meta.product.description', { product, version })}/>
      </Helmet>
      <SideBar/>
      <main className="configurator-content">
        <MediaQuery minDeviceWidth={992}>
          <NavBar/>
          <Gallery feature={selectedFeature}/>
        </MediaQuery>
      </main>
      <Basket/>

      <LoginModal/>
      <IncompatibilitiesModal/>
      <RequirementsModal/>

    </div>
  );
}
